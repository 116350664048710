/* App.css */

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure full viewport height */
    overflow-x: hidden; /*THIS IS IMPORTANT*/

}

main {
    flex: 1; /* Grow to fill available space */
}

.app-header {
  display: flex;
  justify-content: center; /* Center logo */
  align-items: center;
  position: relative;
  padding: 10px;
  background-color: black;
}

.header-logo-button {
  background: none;
  border: none;
  cursor: pointer;
}

.header-logo {
  height: 75px; /* Adjust as needed */
}

.about-button {
  padding: 0px 0px; /* Smaller padding */
  position: absolute;
  right: 20px; /* Stick to the right */
  top: 50%;
  transform: translateY(-50%); /* Center vertically */
}

.header-logo-button {
  background-color: transparent; /* Make background transparent */
  border: none; /* Remove the border */
  padding: 0; /* Remove padding */
  cursor: pointer; /* Keep the pointer cursor */
  display: inline-block; /* Prevent image from collapsing */
}

.background-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./img/basketball_football_logo.jpeg");
  background-size: cover;
  background-position: center;
  z-index: -1; /* Place behind the card */
}

.overlay-card {
  background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent dark gray */
  color: white;
  padding: 20px;
  /* border-radius: 10px; */
  margin: 30px auto; /* Center the card */
  max-width: 800px; /* Set a maximum width */
  text-align: center; /* Center text within the card */
  font-family: "Permanent Marker", serif;
  font-weight: 400;
  font-style: normal;
}

.results-card  {
  background-color: rgba(0, 0, 0, 0.9) !important; /* Semi-transparent dark gray */
  color: white !important;
  border-radius: 0px !important;
  border: 0px !important;
  padding:5px;
  margin: 10px auto; /* Center the card */
  max-width: 800px; /* Set a maximum width */
  text-align: center; /* Center text within the card */
  font-family: "Permanent Marker", serif;
  font-weight: 400;
  font-style: normal;
}

.alt-font {
  font-family: "Doto", serif;
  font-optical-sizing: auto;
  font-weight: 800;
  font-style: normal;
  font-variation-settings: "ROND" 0;
}

.alt-font-roboto {
  font-family: "Roboto", serif;
  font-weight: 400;
  font-style: normal;
}

.marker-font {
  font-family: "Permanent Marker", serif;
  font-weight: 400;
  font-style: normal;
}

.start-button {
  background-color: #124a93; /* Example blue button */
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2rem; /* Make button text larger */
}

.start-button:hover {
  background-color: #03244e; /* Darker blue on hover */
}

/* Team Logo Styling */
.team-logo {
  width: 100%; /* Responsive width */
  max-width: 100px; /* Max size */
  height: auto;
  cursor: pointer;
  border: 2px solid transparent; /* Border for selected state */
  padding: 5px;
  transition: border-color 0.2s ease-in-out; /* Smooth transition */
}

.override-margin {
  margin: 0!important;
  padding: 0!important;
}

.team-logo.selected {
  border-color: #124a93; /* Highlight selected logo */
}

.header-link {
  color: white;
  text-decoration: none; /* Remove underline from link */
}

.header-link:hover {
  color: white; /* Prevent color change on hover */
}

.email-input {
  border: 1px solid gray !important;
}

/* Footer */
.app-footer {
    background-color: rgba(0, 0, 0, 0.92); /* Dark background */
    color: white;
    padding: 10px 0;
    text-align: center;
    width: 100%;
}

.footer-content {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items horizontally */
    justify-content: center; /* Center vertically (optional, for taller footers) */
    margin-bottom: 10px;
}

.inline-logo {
    width: 100px; /* Or whatever size you want */
    height: auto;
    vertical-align: middle; /* Important for inline alignment */
    margin: 0 5px; /* Add some horizontal spacing */
}

/* Logo and Banner */
.img-fluid {
  max-width: 50%;
  height: auto;
}

.transition-screen {
  text-align: center;
  padding: 20px;
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #124a93; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite; /* Animate the loader */
  margin: 20px auto; /* Center the loader */
}

.admin-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.admin-panel {
  padding: 20px;
  background: white;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Mobile Responsiveness (Bootstrap already helps, but add more if needed) */
@media (max-width: 768px) {
  .team-logo {
    max-width: 80px;
  }
}